import React from 'react'
import ContactInfo from './ContactInfo'
import TradeMarkFooter from './TradeMarkFooter'
import Image1 from '../images/RaviKumarT (1).png'
import Image2 from '../images/BalaVamsiT.png'
import Image3 from '../images/VenkatK.png'
import {
  MainContainer,
  SubContainer,
  AboutContainer,
  AboutText,
  Line,
  LeftContainer,
  RightContainer,
  LeftSubContainer,
  PersonName,
  PersonRole,
  PersonImage,
  ContentContainer,
  Paragraph1,
  ContentMainContainer,
  RightSubContainer,
} from './styledHome'

const About = () => {
  return (
    <MainContainer>
      <SubContainer>
        <AboutContainer>
          <Line></Line>
          <AboutText>ABOUT US</AboutText>
          <Line></Line>
        </AboutContainer>
        <ContentMainContainer>
          <LeftContainer>
            <LeftSubContainer>
              <PersonName>T Ravi Kumar</PersonName>
              <PersonRole>Founder and Managing Director</PersonRole>
              <PersonImage src={Image1}></PersonImage>
              <ContentContainer>
                <Paragraph1>
                  Ravi Kumar is the founder and Managing Director who is the
                  brain behind the product Baas®. He has over 40 years of
                  experience of which 25 years in Banking and 14 years in IT
                  bringing a blend of both to the table.
                </Paragraph1>
                <Paragraph1>
                   He has earlier worked
                  with Andhra Bank, Global Trust Bank, ICFAI and Polaris/ Intellect. He
                  also consulted for large banks and IT companies in business
                  process reengineering.
                </Paragraph1>
                <Paragraph1>
                  His key strength is in bringing customer centricity to a
                  product. He has the ability to talk to customers and
                  development team in their language. Hence superimposes the
                  business requirements on a robust technical design bringing
                  scalability and configurability to a product.
                </Paragraph1>
                <Paragraph1>
                  He designed and Implemented applications for global trust
                  banking for a global bank that is used by US, Europe and APAC
                  regions. Designed and implemented for global private banking
                  business of a large Indian private Bank.
                </Paragraph1>
              </ContentContainer>
            </LeftSubContainer>
          </LeftContainer>
          <RightContainer>
            <RightSubContainer>
              <PersonName>T Bala Vamshi</PersonName>
              <PersonRole>Director</PersonRole>
              <PersonImage src={Image2}></PersonImage>
              <ContentContainer>
                <Paragraph1>
                  Bala Vamsi Tatavarthy is one of the founding Directors and
                  provides strategic direction to the company. He has over 12
                  years of experience in investment banking.
                </Paragraph1>
                <Paragraph1>
                  He currently works as a fund advisor at Aravali Asset
                  Management. Prior to this he was an advisor at Ocean Dial
                  Asset Management/Ariana Investment Management. Before that he
                  headed the FX Correlations and Structured Exotics Desk in APAC
                  for Barclays and was a trader in FX Exotic Derivatives in
                  London and New York for Lehman.
                </Paragraph1>
                <Paragraph1>
                  He has a Bachelors and Masters degree in Computer Science from
                  IIT-Madras and an MBA from IIM-Ahmedabad.
                </Paragraph1>
              </ContentContainer>
            </RightSubContainer>
          </RightContainer>
        </ContentMainContainer>
        <LeftContainer>
          <LeftSubContainer>
            <PersonRole> </PersonRole><PersonRole> </PersonRole>
            <PersonName>Venkat Katuri</PersonName>
            <PersonRole>COO </PersonRole>
            <PersonImage src={Image3}></PersonImage>
            <ContentContainer>
              <Paragraph1>
                Katuri heads the delivery of BaaS®. He has over thirty years
                of overall experience across all aspects of Global Delivery
                Model spanning multiple domains such as Banking, Telecom,
                Utilities, Healthcare.
              </Paragraph1>
              <Paragraph1>
                He has earlier worked with companies like HCL, WIPRO, Tech
                Mahindra and Intellect.
              </Paragraph1>
              <Paragraph1>
                His Key strengths are Global Delivery, People Management,
                Business Transformation and Change Management, Product
                Implementations and Rollout. He also has Functional Expertise in
                the Private Banking and Wealth Management domain.
              </Paragraph1>
              <Paragraph1>
                He is a result oriented leader with passion and focus on
                outcomes without losing the focus on people. Katuri has handled
                multiple responsibilities – Developer, Project Manager, Program
                Manager, Change Manager, Implementation Manager and has proven
                both in IT services and products division. He has successfully
                executed Business Transformation initiatives and led change
                initiatives to integrate teams and brought unity in in
                diversity.
              </Paragraph1>
            </ContentContainer>
          </LeftSubContainer>
        </LeftContainer>
        <ContactInfo />
        <TradeMarkFooter />
      </SubContainer>
    </MainContainer>
  )
}
export default About
