import React, { useState, useEffect } from "react";

import { AiOutlineMenu } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import "../homeScreen/Navbar.css";

const MobileHeader = () => {
  const [active, setActive] = useState(false);
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.pageYOffset;

      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;

        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <AiOutlineMenu
        style={{ cursor: "pointer" }}
        size={25}
        onClick={() => {
          setActive(!active);
        }}
      />
      {active && (
        <div className="miniPopup">
          <ul className="nav-menu-mobile">
            <li
              className={`nav-item  `}
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="home"
                className={activeLink === "home" ? "active" : "color1"}
              >
                HOME
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="#solution"
                className={activeLink === "solution" ? "active" : "color1"}
              >
                SOLUTIONS
              </a>
            </li>
            <li
              className="nav-item "
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="#consulting"
                className={activeLink === "consulting" ? "active" : "color1"}
              >
                CONSULTING
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="#partner"
                className={activeLink === "partner" ? "active" : "color1"}
              >
                PARTNER
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="#client"
                className={activeLink === "client" ? "active" : "color1"}
              >
                CLIENTS
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="#about"
                className={activeLink === "about" ? "active" : "color1"}
              >
                ABOUT US
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                setActive(!active);
              }}
            >
              <a
                href="https://modelbank.finakon.in/bankaudit/#/login/modelbank"
                target="_blank" rel="noreferrer"
                className="color1"
              >
                <div
                  style={{
                    alignItems: "center",
                  }}
                >
                  <BsFillPersonFill
                    size={20}
                    style={{ marginTop: -5, marginRight: 3 }}
                  />
                  lOGIN
                </div>
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default MobileHeader;
