import React from 'react'
import { TradeContainer, TradeTextContainer, TradeText } from './styledHome'
import { AiOutlineCopyrightCircle } from 'react-icons/ai'

const TradeMarkFooter = () => {
  return (
    <TradeContainer>
      <TradeTextContainer>
        <AiOutlineCopyrightCircle
          color="white"
          size={20}
          style={{ marginBottom: 15 }}
        />
        <TradeText> 2018 All Rights Reserved, Finakon.co.in</TradeText>
      </TradeTextContainer>
    </TradeContainer>
  )
}
export default TradeMarkFooter
