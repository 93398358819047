import React from "react";
import cap from "../images/cap.png";
import sglogo from "../images/smartgigs.png";
import {
  AboutText,
  Maindiv,
  Line,
  AboutContainer,
  Seconddiv,
  Firstchiled,
  Secondchiled,
  Firstline,
  FirstchiledText,
  Imagediv,
} from "./styledHome";

const Home = () => {
  const image = cap;
  const smartgiglogo = sglogo;
  return (
    <Maindiv>
      <AboutContainer>
        <Line></Line>
        <AboutText>PARTNER</AboutText>
        <Line></Line>
      </AboutContainer>
      <Seconddiv>
        <Firstchiled>
          <Imagediv>
            <img
              alt="partner"
              src={image}
              style={{ width: "200px", height: "75px", marginTop:'0px',}}
            />
            <a
              style={{
                color: "black",
                fontSize: 20,
                fontWeight: 500,
                marginTop: 12,
                textDecoration: "none",
              }}
              href="https://www.caprusit.com/"
              target="_blank" rel="noreferrer"

            >
              www.caprusit.com
            </a>
            <img
              alt="partner"
              src={smartgiglogo}
              style={{ width: "175px", height: "75px", marginTop:'20px', }}
            />
            <a
              style={{
                color: "black",
                fontSize: 20,
                fontWeight: 500,
                marginTop: 12,
                textDecoration: "none",
              }}
              href="https://www.smartgig.tech/"
              target="_blank" rel="noreferrer"
            >
              www.smartgig.tech
            </a>
              
          </Imagediv>
        </Firstchiled>
        <Secondchiled>
          <Firstline>
            CaprusIT and SmartGig are our technology partners. They are involved in
          </Firstline>
          <FirstchiledText>• Development,</FirstchiledText>
          <FirstchiledText>• Implementation and</FirstchiledText>
          <FirstchiledText>• Support</FirstchiledText>
          <Firstline>for the products of finakon.</Firstline>
        </Secondchiled>
      </Seconddiv>
    </Maindiv>
  );
};
export default Home;
