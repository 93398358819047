import React from "react";
import nabard from '../images/clients.png';
import {
  AboutText,
  Clientsdiv,
  Line,
  AboutContainer,
  Seconddiv,
} from "./styledHome";

const Clients = () => {
  return (
    <Clientsdiv>
      <AboutContainer>
        <Line></Line>
        <AboutText>CLIENTS</AboutText>
        <Line></Line>
      </AboutContainer>
      <Seconddiv>
          <div style={{padding:'10px'}}>
          <img
            alt="nabard"
            src={nabard}
            style={{
              width: "1000px",
              height: "500px",
              
            }}
          />
          </div>
      </Seconddiv>
    </Clientsdiv>
  );
};
export default Clients;
