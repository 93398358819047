import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
`;
const SubContainer = styled.div`
  justify-content: center;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const AboutContainer = styled.div`

  display: flex;
  width:100%,
  flex-direction: row;
  align-items: center;
  margin-top:30px;
  margin-bottom:20px;
  justify-content: center;
  @media only screen and (max-width: 900px) {
  margin-top:50px;
  margin-bottom:25px;
  }

`;

const AboutText = styled.h3`
  font-size: 30px;
  color: #286c93;
  font-weight: 600;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`;
const AboutContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;
const AboutText1 = styled.h3`
  font-size: 30px;
  color: #286c93;
  font-weight: 600;
`;
const Line = styled.hr`
  height: 4px;
  width: 100px;
  border-radius: 3px;
  background-color: #286c93;
  margin-right: 20px;
  margin-left: 20px;
  @media only screen and (max-width: 900px) {
    margin-left: 10px;
    margin-right: 10px;
    width: 80px;
  }
`;
const LeftContainer = styled.div`
  width: 48%;
  padding-left: 30px;
  padding-right: 30px;
  @media only screen and (max-width: 900px) {
    width: 100vw;
    padding-left: 10px;
    padding-right: 0px;
    align-items: center;
  }
  @media only screen and (max-width: 1300px) {
    padding-left: 10px;
    padding-right: 0px;
    align-items: center;
  }
`;
const LeftSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const PersonName = styled.h1`
  font-size: 26px;
  margin-bottom: 0px;
  color: #212529;
  font-weight: 500;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`;
const PersonRole = styled.p`
  font-size: 24px;
  font-weight: normal;
  color: #286c93;
  margin-top: 10px;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`;
const PersonImage = styled.img`
  vertical-align: middle;
  border-style: none;

  height: 200px;
  width: 200px;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Paragraph1 = styled.h1`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: normal;
  text-align: justify;
  @media only screen and (max-width: 900px) {
    width: 98%;
    font-size: 18px;
    line-height: 25px;
  }
  @media only screen and (900px <=width<=1300px) {
    font-size: 15px;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  @media only screen and (max-width: 900px) {
    width: 100vw;
    padding-left: 10px;
    padding-right: 0px;
    align-items: center;
  }
  @media only screen and (max-width: 1300px) {
    padding-left: 10px;
    padding-right: 0px;
    align-items: center;
  }
`;
const ContentMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:space-between
  align-self: center;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    max-width:100%;
  }
  @media only screen and (min-width: 900px) {
    flex-direction: row;
    max-width:100%;
  }
  

`;
const RightSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ContactMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  width: 100%;
  padding-bottom: 30px;
`;
const IconImage = styled.img`
  height: 25px;
  width: 25px;
  margin: 0px;
  align-self: flex-start;
  @media only screen and (max-width: 900px) {
    margin-top: 0px;
    height: 18px;
    width: 18px;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  margin-bottom: 20px;
  margin-top: 0px;
`;
const ValueText = styled.p`
  margin: 0px;
  margin-left: 40px;
  max-width: 300px;
  font-size: 16px;
  font-weight: normal;
  color: #212529;
  text-align: left;
  @media only screen and (max-width: 900px) {
    line-height: 20px;
    font-size: 15px;
  }
`;
const EmailText = styled.p`
 margin: 0px;
  margin-left: 40px;
  max-width: 300px;
  font-size: 16px;
  font-weight: normal;
  color: #212529;
  text-align: left;
  text-decoration:underline;
  @media only screen and (max-width: 900px) {
    line-height: 20px;
    font-size: 15px;
  }
`;
const TradeContainer = styled.div`
  width: 100%;
  @media only screen and (max-width: 900px) {
    width: 100vw;
  }
`;
const TradeTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #343b40;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 0px;
  @media only screen and (max-width: 900px) {
    width: 100vw;
  }
`;
const TradeText = styled.p`
  color: white;
  font-size: 18px;
  margin-left: 5px;
  text-align: center;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
`;

// Home
const PlayerCont = styled.div`
  margin-top: 90px;
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    height: 220px;
  }
  @media only screen and (601px <=width<=900px) {
    width: 100%;
    height: 50vh;
    padding-top: 82px;
  }
`;

const TextCon = styled.div`
  background-color: #f7f7f7;
  padding: 20px 100px;
  width: 98vw;
  // Extrasmall
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 10px 10px;
  }
  @media only screen and (900px <= width <= 1300px) {
    padding: 5px 15px;
    width: 100%;
  }
`;

const HeadingText = styled.h1`
  font-size: 24px;
  color: #212529;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding-left: 0px;
    abckground-color: red;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    padding: 0px 10px;
  }
`;
const RiseImage = styled.img`
  width: "80%";
  height: "100%";
  align-self: center;
  @media only screen and (max-width: 900px) {
    width: 100vw;
    padding: 10px 0px;
  }
  @media only screen and (max-width: 1024px) {
    width: 80%;
    padding: 10px 0px;
  }
`;
const ListCon = styled.ul`
  list-style-type: none;
  @media only screen and (max-width: 900px) {
    width: 100vw;
    padding-left: 0px;
  }
`;

const ListItem = styled.li`
  color: #286c93;
  text-align: center;

  font-size: 24px;
  line-height: 50px;
  @media only screen and (max-width: 900px) {
    padding-left: 0px;
    width: 98vw;
    line-height: 40px;
    font-size: 20px;
  }
`;

const NameText = styled.ul`
  color: red;
  line-height: 120px;
`;

const SubCont = styled.div`
  display: flex;
`;

//solutions

const SolutionsBottomCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  margin: 0 auto;
  @media only screen and (max-width: 900px) {
    padding-left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (600px <=width<=900px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const RiseCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

//consulting

const ConsultingCont = styled.div`
  padding: 10px 0 0 0;
  background-color: #f7f7f7;
`;

const ConsultingContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media only screen and (max-width: 900px) {
    width: 100vw;
    flex-direction: column;
  }
`;
const ConsultingImage = styled.div`
  width: 650px;
  height: 420px;
  margin-top: 0px;
  @media only screen and (max-width: 900px) {
    width: 100%;
    width: 300px;
    height: 220px;
    margin-top: 0px;
  }
`;

const ConsultingText = styled.p`
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
  color: #212529;
`;

const ConsultingServiceText = styled.p`
  margin: 20px 0;
  text-align: center;
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 50px;
`;

const ConsultingTextCon = styled.div`
  font-size: 24px;
  max-width: 60%;
  margin-top: 0px;
  line-height: 50px;
  text-align: justify;
`;

const ConsultingList = styled.div`
  display: flex;
  flex-direction: row;
`;

const ConsultingItem = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`;

// Patner
const Maindiv = styled.div`
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

`;

const Seconddiv = styled.div`
  display: flex;
  justify-content:center !important;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;
`;

const Firstchiled = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const Secondchiled = styled.div`
  width: 50%;
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;
const Firstline = styled.h1`
  line-height: 40px;
  letter-spacing: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 490;
  font-size: 24px;
  color: #212529;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
`;

const FirstchiledText = styled.h1`
margin:0px,
padding: 0px;
line-height: 40px;
letter-spacing: 1;
font-weight: 700;
font-size: 24px;
margin-bottom: 10px;
color: #212529;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

const Imagediv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
// client
const Clientsdiv = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  background-color: #f7f7f7;
`;
const Rythmos = styled.div`
  width: 40%;
  justify-content: center;
  display: flex;
  @media only screen and (max-width: 900px) {
    align-self: center;
    justify-content: center;
    width: 100px;
  }
`;
const Clientp = styled.h1`
  line-height: 40px;
  letter-spacing: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 490;
  font-size: 24px;
  color: #212529;
  @media only screen and (max-width: 900px) {
    font-size: 20px;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
  }
`;
// header
const NavContainer = styled.div`
  box-shadow: 0 1px 4px rgb(0 0 0 / 35%);
  border-bottom: 1px solid grey;
  height: 80px;
`;
const IconCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff9800;
  cursor: pointer;
`;

const ImageLeave = styled.img`
  height: 50px;
  user-select: none;
  width: 180px;
  z-index: 10;
`;

const PopMail = styled.div`
  align-self: flex-end;
  position: fixed;
  bottom: 80px;
  right: 0px;
  margin: 15px;
  display: flex;
  align-items: center;
`;

const PopupMain = styled.div`
  position: fixed;
  width: 320px;
  height: auto;
  right: 15px;
  bottom: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: 10;
  pointer-events: all;
  box-shadow: rgb(0 0 0 / 35%) 0px 1px 4px;
`;

const PopupHead = styled.div`
  max-width: 100%;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 152, 0);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
`;

const PopupText = styled.h3`
  font-size: 22px;
  margin: 0px;
  font-weight: 499;
`;

const InputsCont = styled.form`
  padding: 20px 20px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
`;

const CrossImg = styled.img`
  cursor: pointer;
  z-index: 99;
  width: 20px;
  height: 20px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid lightgrey;
  outline: none;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.5s ease 0s;
  position: relative;
  margin-bottom: 12px;
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid lightgrey;
  outline: none;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.5s ease 0s;
  position: relative;
  margin-bottom: 12px;
`;

const ErrorText = styled.p`
  align-self: flex-start;
  color: rgba(255, 0, 0, 0.694);
  font-size: 14px;
  padding: 0px;
  font-weight: 499;
  margin-bottom: 4px;
  color: red;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: white;
  border: none;
  outline: none;
  user-select: none;
  background-color: rgb(255, 152, 0);
  border-radius: 10px;
  cursor: pointer;
  width: 100px;
  align-self: center;
  margin-top: 15px;
`;
const Button = styled.div`
  background-color: lightgrey;
  align-self: flex-end;
  position: fixed;
  bottom: 30px;
  color: black;
  right: 6px;
  cursor: pointer;

  margin: 15px;
  display: flex;
  align-items: center;
  border-radius: 50%;
`;
const SuccessScreen = styled.div`
  position: fixed;
  width: 320px;
  height: auto;
  right: 15px;
  top: 200px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: 10;
  pointer-events: all;
  box-shadow: rgb(0 0 0 / 35%) 0px 1px 4px;
`;
const SuccessCont = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 320px;
  position: absolute;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0px;
`;

const SuccessSub = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckIconCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(28, 194, 28);
  border-radius: 50%;
  padding: 15px;
  text-align: center;
  align-self: center;
  margin-bottom: 20px;
`;

const SuccessText = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: center;
  color: rgb(255, 255, 255);
`;

// Side Menu
const SideMenuContainer = styled.div`
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: end;
`;
const UserContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  background-color: #f1f1f1;
  justify-content: center;
  padding-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 30px;
`;

export {
  Button,
  ButtonContainer,
  UserContainerMain,
  SuccessCont,
  SuccessText,
  CheckIconCon,
  SuccessSub,
  SuccessScreen,
  Clientp,
  SubmitButton,
  IconCon,
  ImageLeave,
  PopMail,
  PopupMain,
  PopupHead,
  TextArea,
  PopupText,
  InputsCont,
  CrossImg,
  Input,
  ErrorText,
  MainContainer,
  RiseCont,
  SubContainer,
  AboutContainer,
  AboutText,
  AboutContainer1,
  AboutText1,
  Line,
  LeftContainer,
  RightContainer,
  LeftSubContainer,
  PersonName,
  PersonRole,
  PersonImage,
  ContentContainer,
  Paragraph1,
  ContentMainContainer,
  RightSubContainer,
  ContactMainContainer,
  IconImage,
  FooterContainer,
  ValueText,
  TradeContainer,
  TradeTextContainer,
  TradeText,
  PlayerCont,
  TextCon,
  HeadingText,
  ConsultingTextCon,
  ConsultingList,
  ConsultingItem,
  ListCon,
  ListItem,
  NameText,
  SubCont,
  SolutionsBottomCon,
  ConsultingCont,
  ConsultingContent,
  ConsultingImage,
  ConsultingText,
  ConsultingServiceText,
  Maindiv,
  Seconddiv,
  Firstchiled,
  Secondchiled,
  Firstline,
  FirstchiledText,
  Imagediv,
  Clientsdiv,
  Rythmos,
  NavContainer,
  RiseImage,
  SideMenuContainer,
  EmailText,
};
