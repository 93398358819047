import React from 'react'
import {
  AboutContainer,
  Line,
  AboutText,
  ContactMainContainer,
  IconImage,
  FooterContainer,
  ValueText,
  EmailText
} from './styledHome'

const ContactInfo = () => {
  const phone = 'https://finakon.in/assets/call.svg'
  const mail = 'https://finakon.in/assets/email_blue.svg'
  const time = 'https://finakon.in/assets/time.svg'
  const location = 'https://finakon.in/assets/location.svg'
  return (
    <ContactMainContainer>
      <AboutContainer>
        <Line></Line>
        <AboutText>CONTACT INFO</AboutText>
        <Line></Line>
      </AboutContainer>
      <FooterContainer>
        <IconImage src={phone}></IconImage>
        <ValueText>+91 9849 805 034</ValueText>
      </FooterContainer>
      <FooterContainer>
        <IconImage src={mail}></IconImage>
        <EmailText onClick={()=>window.open('mailto:sales@finakon.in')}>sales@finakon.in</EmailText>
      </FooterContainer>
      <FooterContainer>
        <IconImage src={time}></IconImage>
        <ValueText>Mon - Fri : 09:00 - 18:00</ValueText>
      </FooterContainer>
      <FooterContainer>
        <IconImage src={location}></IconImage>
        <ValueText>
          Level 4, Sanali Spazio, Inorbit Mall Road, Madhapur, Hyderabad-500 081, Telangana,
          India.
        </ValueText>
      </FooterContainer>
    </ContactMainContainer>
  )
}
export default ContactInfo
