import React from "react";
import "./App.css";
import Clients from "./Components/homeScreen/Clients";
import Patner from "./Components/homeScreen/Patner";
import Solutions from "./Components/homeScreen/Solutions";
import Consulting from "./Components/homeScreen/Consulting";
import Header from "./Components/homeScreen/Header";
import About from "./Components/homeScreen/About";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Home from "./Components/homeScreen/Home";

import "../src/Components/homeScreen/Navbar.css";

function App() {
  return (
    <>
      <div style={{ width: "100%" }}>
        <Header />
        <section id="home">
          <Home />
        </section>
        <section id="solution">
          <Solutions />
        </section>
        <section id="consulting">
          <Consulting />
        </section>
        <section id="partner">
          <Patner />
        </section>
        <section id="client">
          <Clients />
        </section>
        <section id="about">
          <About />
        </section>
      </div>
    </>
  );
}
export default App;
