import React from "react";

// import ReactPlayer from "react-player";
import {
  PlayerCont,
  HeadingText,
  NameText,
  TextCon,
  ListCon,
  ListItem,
} from "../homeScreen/styledHome";

import video from "../images/main_video.mp4";

const Home = () => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <PlayerCont>
        <video
          src={video}
          autoPlay={true}
          muted
          loop
          style={{ width: "96vw", height: "80vh" }}
        />
        {/* <ReactPlayer playing={true} loop={true} url={video} /> */}
      </PlayerCont>
      <TextCon>
        <HeadingText>
          <span style={{ fontWeight: 700 }}>Finakon</span> is a start up that
          provides software solutions in niche areas of banking and also
          consulting in the areas of process reengineering and software
          solutions. Banks strive hard to maintain their individual identity in
          serving a customer notwithstanding the common environment to which
          they are subjected to. These efforts result in evolution of a
          distinctive approach. The pace at which Banks work to grow in newer
          areas of business, seldom they get an opportunity to validate the
          current processes. When a Bank chooses to implement a new software
          solution, there is a tendency to carry forward the legacy for two
          principal reasons:
        </HeadingText>
        <ListCon>
          <ListItem>
            <span style={{ fontWeight: 800 }}>•</span> A justifiable belief that
            they are carrying forward a tried and tested method
          </ListItem>
          <ListItem>
            <span style={{ fontWeight: 800 }}>•</span> The need for a validation
            of existing process is realised but there isn’t enough bandwidth
          </ListItem>
        </ListCon>
        <HeadingText>
          We step in to support the Bank in its endeavour to validate and ensure
          that the software implementation results in operational efficiency and
          the quality of work done by the employees has improved and life is not
          simply a matter of “Old Wine in a New Bottle”
        </HeadingText>
      </TextCon>
      <NameText />
    </div>
  );
};
export default Home;
