import React from "react";
import {
  AboutContainer,
  AboutText,
  HeadingText,
  Line,
  SolutionsBottomCon,
  SubContainer,
  RiseImage,
  RiseCont,
} from "../homeScreen/styledHome";

import BaaSFeaturesver from "../images/BaaSFeaturesver.pdf";

import BaaSProcess from "../images/BaaSProcess.pdf";

import Feature from "../images/Feature.jpg";

import process from "../images/process.jpg";

import rise from "../images/rise.png";

const Solutions = () => {
  return (
    <div>
      <SubContainer>
        <AboutContainer>
          <Line></Line>
          <AboutText>SOLUTIONS</AboutText>
          <Line></Line>
        </AboutContainer>
        <HeadingText>
          BaaS® , an application that automates internal audit of Banks,
          <span style={{ color: "#286c93" }}> available on cloud</span>.
        </HeadingText>
        <RiseCont>
          <RiseImage src={rise} />
        </RiseCont>
      </SubContainer>
      <SolutionsBottomCon>
        <a href={BaaSFeaturesver} target="_blank" rel="noreferrer">
          <img
            style={{ heigh: "100px", width: "100px", marginRight: 15 }}
            src={Feature}
            alt="file"
          />
        </a>
        <a href={BaaSProcess} target="_blank" rel="noreferrer">
          <img
            style={{ heigh: "100px", width: "100px", marginRight: 15 }}
            src={process}
            alt="file"
          />
        </a>
      </SolutionsBottomCon>
    </div>
  );
};

export default Solutions;
