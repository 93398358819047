import React from "react";
import Image4 from '../images/vector_image.png';
import {
  AboutContainer,
  AboutText,
  ConsultingCont,
  ConsultingContent,
  ConsultingImage,
  ConsultingItem,
  ConsultingList,
  ConsultingServiceText,
  ConsultingText,
  ConsultingTextCon,
  Line,
} from "../homeScreen/styledHome";

const Consulting = () => {
  return (
    <div>
      <ConsultingCont>
        <AboutContainer>
          <Line />
          <AboutText>CONSULTING</AboutText>
          <Line />
        </AboutContainer>
        <ConsultingContent>
          <ConsultingImage>
            <img
              style={{ height: "auto", width: "100%" }}
              src={Image4}
              alt="consulting-content"
            />
          </ConsultingImage>
          <ConsultingTextCon>
            <ConsultingText>
              Business processes in a bank are essentially driven by three
              dimensions. At times these three dimensions tend to contradict
              each other. It will be necessary to create an optimal process.
              Considering that Banks would like to adopt industry best
              practices, it is always useful to get an insider view of other
              banks practices which will be available with teams that have had
              consulting experience.
            </ConsultingText>
            <ConsultingServiceText>
              We provide services in areas such as
            </ConsultingServiceText>
            <ConsultingList>
              <ConsultingItem>
                • Process Definition before RFP • Preparation of RFP •
                Requirements Management • Business Process Reengineering
              </ConsultingItem>
            </ConsultingList>
          </ConsultingTextCon>
        </ConsultingContent>
      </ConsultingCont>
    </div>
  );
};

export default Consulting;
