import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import finakon_logo from "../images/finakon_logo.png";
import "./Navbar.css";

import MobileHeader from "./MobileHeader";

const Header = () => {
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.pageYOffset;

      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;

        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header">
      <nav className="navbar">
        <img src={finakon_logo} alt="logo" />
        <ul className={"nav-menu"}>
          <li className={`nav-item  `}>
            <a
              href="#home"
              className={activeLink === "home" ? "active" : "color1"}
            >
              HOME
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#solution"
              className={activeLink === "solution" ? "active" : "color1"}
            >
              SOLUTIONS
            </a>
          </li>
          <li className="nav-item ">
            <a
              href="#consulting"
              className={activeLink === "consulting" ? "active" : "color1"}
            >
              CONSULTING
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#partner"
              className={activeLink === "partner" ? "active" : "color1"}
            >
              PARTNER
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#client"
              className={activeLink === "client" ? "active" : "color1"}
            >
              CLIENTS
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#about"
              className={activeLink === "about" ? "active" : "color1"}
            >
              ABOUT US
            </a>
          </li>
        </ul>
        <div style={{ marginRight: 20 }} className="mobileHeader">
          <MobileHeader />
        </div>
      </nav>
    </div>
  );
};
export default Header;
